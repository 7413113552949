export function inside () {

  return [
    {
      path: '/ehr/patient',
      name: 'patient',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Patient.vue'),
      meta: { layout: 'inside', label: 'Patient profile', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/demographics',
      name: 'demographics',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Demographics.vue'),
      meta: { layout: 'inside', label: 'Demographics', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/allergies',
      name: 'allergies',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Allergies.vue'),
      meta: { layout: 'inside', label: 'Allergies', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history',
      name: 'history',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/History.vue'),
      meta: { layout: 'inside', label: 'History', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/medical',
      name: 'medical',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Medical.vue'),
      meta: { layout: 'inside', label: 'Medical history', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/psychosocial',
      name: 'psychosocial',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Psychosocial.vue'),
      meta: { layout: 'inside', label: 'Psychosocial history', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/surgical',
      name: 'surgical',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Surgical.vue'),
      meta: { layout: 'inside', label: 'Surgical history', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/immunization',
      name: 'immunization',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Immunization.vue'),
      meta: { layout: 'inside', label: 'Immunization history', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/family-history',
      name: 'family-history',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/FamilyHistory.vue'),
      meta: { layout: 'inside', label: 'Family history', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/care-team',
      name: 'care-team',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CareTeam.vue'),
      meta: { layout: 'inside', label: 'Care team', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/past-appointments',
      name: 'past-appointments',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/PastAppointments.vue'),
      meta: { layout: 'inside', label: 'Past appointments', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/current',
      name: 'current',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Current.vue'),
      meta: { layout: 'inside', label: 'Current visit', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/visit-details',
      name: 'visit-details',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/VisitDetails.vue'),
      meta: { layout: 'inside', label: 'Admission, diagnosis, location', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/vital-signs',
      name: 'vital-signs',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/VitalSigns.vue'),
      meta: { layout: 'inside', label: 'Vital signs', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/fluid-balance',
      name: 'fluid-balance',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/FluidBalance.vue'),
      meta: { layout: 'inside', label: 'Fluid balance', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments',
      name: 'assessments',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Assessments.vue'),
      meta: { layout: 'inside', label: 'Assessments', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/neurological',
      name: 'neurological',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Neurological.vue'),
      meta: { layout: 'inside', label: 'Neurological assessments', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/respiratory',
      name: 'respiratory',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Respiratory.vue'),
      meta: { layout: 'inside', label: 'Respiratory assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/cardiovascular',
      name: 'cardiovascular',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Cardiovascular.vue'),
      meta: { layout: 'inside', label: 'Cardiovascular assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/gastrointestinal',
      name: 'gastrointestinal',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Gastrointestinal.vue'),
      meta: { layout: 'inside', label: 'Gastrointestinal assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/genitourinary',
      name: 'genitourinary',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Genitourinary.vue'),
      meta: { layout: 'inside', label: 'Genitourinary assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/musculoskeletal',
      name: 'musculoskeletal',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Musculoskeletal.vue'),
      meta: { layout: 'inside', label: 'Musculoskeletal assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/pain',
      name: 'pain',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Pain.vue'),
      meta: { layout: 'inside', label: 'Pain assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/biopsychosocial',
      name: 'biopsychosocial',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Biopsychosocial.vue'),
      meta: { layout: 'inside', label: 'Biopsychosocial assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders',
      name: 'orders',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Orders.vue'),
      meta: { layout: 'inside', label: 'Orders', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/no-med-orders',
      name: 'no-med-orders',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/NoMedOrders.vue'),
      meta: { layout: 'inside', label: 'Non-medication orders', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/referrals',
      name: 'referrals',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Referrals.vue'),
      meta: { layout: 'inside', label: 'Referrals to other disciplines', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/lab-requisitions',
      name: 'lab-requisitions',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/LabRequisitions.vue'),
      meta: { layout: 'inside', label: 'Lab requisitions', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/meds',
      name: 'meds',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Meds.vue'),
      meta: { layout: 'inside', label: 'Medication orders', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/mar',
      name: 'mar',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Mar.vue'),
      meta: { layout: 'inside', label: 'Medication administration records', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/discharge',
      name: 'discharge',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Discharge.vue'),
      meta: { layout: 'inside', label: 'Discharge summary', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/billing',
      name: 'billing',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Billing.vue'),
      meta: { layout: 'inside', label: 'Billing', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/chart',
      name: 'chart',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Chart.vue'),
      meta: { layout: 'inside', label: 'Patient chart', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/progress-notes',
      name: 'progress-notes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/ProgressNotes.vue'),
      meta: { layout: 'inside', label: 'Progress notes', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/care-plan',
      name: 'care-plan',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CarePlan.vue'),
      meta: { layout: 'inside', label: 'Interprofessional plan of care', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports',
      name: 'reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Reports.vue'),
      meta: { layout: 'inside', label: 'Reports and documents', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/consults',
      name: 'consults',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Consults.vue'),
      meta: { layout: 'inside', label: 'Consults', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/lab-reports',
      name: 'lab-reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/LabReports.vue'),
      meta: { layout: 'inside', label: 'Laboratory reports', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/diagnostic-reports',
      name: 'diagnostic-reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DiagnosticReports.vue'),
      meta: { layout: 'inside', label: 'Medical imaging', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/operative-anaesthesia',
      name: 'operative-anaesthesia',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/OperativeAnaesthesia.vue'),
      meta: { layout: 'inside', label: 'Operative and anaesthesia records', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/external',
      name: 'external',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/External.vue'),
      meta: { layout: 'inside', label: 'External resources', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/assessment-tools',
      name: 'assessment-tools',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/AssessmentTools.vue'),
      meta: { layout: 'inside', label: 'Standardized assessment tools', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/code-lookup',
      name: 'code-lookup',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CodeLookup.vue'),
      meta: { layout: 'inside', label: 'Code lookup', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/diagnostic-codes',
      name: 'diagnostic-codes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DiagnosticCodes.vue'),
      meta: { layout: 'inside', label: 'Diagnostic codes (ICD-10)', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/intervention-codes',
      name: 'intervention-codes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/InterventionCodes.vue'),
      meta: { layout: 'inside', label: 'Intervention codes', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/case-mix-group',
      name: 'case-mix-group',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CaseMixGroup.vue'),
      meta: { layout: 'inside', label: 'Case mix group', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/integumentary-assessment',
      name: 'integumentary-assessment',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/IntegumentaryAssessment.vue'),
      meta: { layout: 'inside', label: 'Integumentary assessment', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/discharge-plan',
      name: 'discharge-plan',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DischargePlan.vue'),
      meta: { layout: 'inside', label: 'Discharge plan', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab',
      name: 'med-lab',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLab.vue'),
      meta: { layout: 'inside', label: 'Med Lab', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/hematology',
      name: 'hematology',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Hematology.vue'),
      meta: { layout: 'inside', label: 'Med Lab Hematology', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-chemistry',
      name: 'med-lab-chemistry',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabChemistry.vue'),
      meta: { layout: 'inside', label: 'Med Lab Chemistry', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-urinalysis',
      name: 'med-lab-urinalysis',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabUrinalysis.vue'),
      meta: { layout: 'inside', label: 'Med Lab Urinalysis', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/order-sets',
      name: 'order-sets',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/OrderSets.vue'),
      meta: { layout: 'inside', label: 'Clinical Order Sets', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-accession',
      name: 'med-lab-accession',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/MedLabAccession.vue'),
      meta: { layout: 'inside', label: 'Med Lab Accessioning', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/risk-assessment',
      name: 'risk-assessment',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/RiskAssessment.vue'),
      meta: { layout: 'inside', label: 'Risk assessments', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-phone-log',
      name: 'med-lab-phone-log',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabPhoneLog.vue'),
      meta: { layout: 'inside', label: 'Phone Log', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/levey-jennings',
      name: 'levey-jennings',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/LeveyJennings.vue'),
      meta: { layout: 'inside', label: 'Levey-Jennings', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-body',
      name: 'med-lab-body',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabBody.vue'),
      meta: { layout: 'inside', label: 'Body Fluids', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/acp-most',
      name: 'acp-most',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/AcpMost.vue'),
      meta: { layout: 'inside', label: 'Advanced care plans', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/current/consents',
      name: 'consents',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Consents.vue'),
      meta: { layout: 'inside', label: 'Consents', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/checklists',
      name: 'checklists',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Checklists.vue'),
      meta: { layout: 'inside', label: 'Checklists', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/special-indicators',
      name: 'special-indicators',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/SpecialIndicators.vue'),
      meta: { layout: 'inside', label: 'Special indicators', topLevel: 'current', zone: 'ehr' }
    }
  ]
}
